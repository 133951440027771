<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!-- <div>
        <span>2022-01-20 以前的订单修改记录请</span>
        <span @click="goquery()" class="goquery">点此查询</span>
      </div> -->
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Parking_Order_ID')">
                <el-input
                  type="text"
                  v-model="formInline.debtOrderId"
                  :onkeyup="(formInline.debtOrderId = formInline.debtOrderId.replace(/[^\d]/g, ''))"
                  placeholder="请输入"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="handleAreaChange"></a-cascader>
          </el-form-item>-->
              <!-- <el-form-item label="城市">
            <el-select v-model.trim="formInline.areaId"
                       @change='areaChange'
                       filterable
                       placeholder=""
                       size="10">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.streetId"
                       filterable
                       size="15"
                       placeholder="请选择"
                       @change="$refs.parkInput.setValue('');formInline.operationId = ''">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1,2"
                ></my-component>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <el-select v-model.trim="formInline.parkType" filterable placeholder="" size="15">
              <el-option label="全部" value="-1"></el-option>
              <el-option
                :label="park.desc"
                :value="park.code"
                :key="park.code"
                v-for="park in parkType"
              ></el-option>
            </el-select>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="欠费区间">
                <el-input
                  type="text"
                  v-model="formInline.money1"
                  :onkeyup="(formInline.money1 = formInline.money1.replace(/[^\d]/g, ''))"
                  maxlength="6"
                  style="width: 120px"
                  placeholder="请输入"
                ></el-input
                >至
                <el-input
                  type="text"
                  v-model="formInline.money2"
                  :onkeyup="(formInline.money2 = formInline.money2.replace(/[^\d]/g, ''))"
                  maxlength="6"
                  style="width: 120px"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
                <el-select v-model.trim="formInline.entryExitType" filterable size="10">
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ExitTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Parking_duration')">
            <el-select v-model.trim="formInline.parkTime" placeholder="" size="15">
              <el-option label="全部" value="-1"></el-option>
              <el-option label="0-1小时" :value="1"></el-option>
              <el-option label="1-24小时" :value="24"></el-option>
              <el-option label="大于24小时" :value="25"></el-option>
            </el-select>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.time_slot')">
                <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"
                            @change="setStartTime"> </el-date-picker> 至
            <el-date-picker v-model="date2"
                            type="datetime"
                            placeholder="选择日期"
            @change="setEndTime"> </el-date-picker>-->
                <timeRangePick
                  :clearable="false"
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Order_modification_time')">
                <timeRangePick
                  :clearable="false"
                  @timeChange="timeChangeModify"
                  ref="timeRangePickerModify"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Reason_for_modification')">
                <el-select v-model.trim="formInline.modifyType" placeholder size="15">
                  <el-option label="全部" value></el-option>
                  <el-option label="订单自动找平" :value="0"></el-option>
                  <el-option label="异常订单处理" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                v-if="$route.meta.authority.tabs.oweOrder.tabs.debtUpate.button.query"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.tabs.oweOrder.tabs.debtUpate.button.export"
          ></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                style="display: inline-block"
                @click="exportFile"
                v-if="$route.meta.authority.tabs.oweOrder.tabs.debtUpate.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'parkRecordId'"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.parkRecordId }}</p>
              </span>
              <span
                v-else-if="item.prop == 'parkOrderAppealId'"
                v-clipboard:copy="scope.row.parkOrderAppealId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.parkOrderAppealId }}</p>
              </span>
              <span v-else>
                <p v-html="item.formatter ? item.formatter(scope.row) : scope.row[item.prop]">}</p>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      class="dialogVisible"
      title="欠费提醒配置"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form
        @submit.native.prevent="submit"
        ref="form"
        :rules="rules"
        :model="arrearsForm"
        label-width="120px"
        style="width: 80%"
      >
        <el-form-item label="欠费提醒金额" prop="debtMoney">
          <el-input
            width="120px"
            maxlength="7"
            placeholder="请输入欠费提醒金额"
            type="text"
            v-model="arrearsForm.debtMoney"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, setIndex, onlyNumber } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import qs from "qs";
// import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "parkingArrearageOrder",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输十一个字符"));
      }
    };
    return {
      defalutDate: [],
      onlyNumber,
      rules: {
        debtMoney: [
          { required: true, message: "欠费提醒金额不能为空" },
          {
            validator: (rule, value, callback) => {
              this.arrearsForm.debtMoney = this.onlyNumber(value);
              callback();
            },
            trigger: "change",
          },
        ],
      },
      dialogVisible: false,
      arrearsForm: {
        debtMoney: "",
      },
      state2: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      roleList: [],
      plateList: [],
      ExitTypeList: [],
      merchantList: [],
      berthList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
          width: "",
        },
        {
          prop: "money",
          label: "欠费金额(元)",
          formatter: (row) => {
            if (row.money && row.oldMoney) {
              let y = Number(row.oldMoney / 100).toFixed(2);
              let x = Number(row.money / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            } else {
              let y = Number(row.oldMoney / 100).toFixed(2);
              let x = Number(row.money / 100).toFixed(2);
              let data = `<s>${y}</s><span style="color:#0f6eff;margin-left:10px;">${x}</span>`;
              return x == y ? `<span margin-left:10px;">${y}</span>` : data;
            }
          },
        },
        {
          prop: "modifyTime",
          label: "订单修改时间",
          width: "",
        },
        {
          prop: "modifyReason",
          label: "修改原因",
          width: "",
        },
        {
          prop: "parkOrderAppealId",
          label: "处理记录ID",
          width: "",
        },
        // {
        //   prop: "cityName",
        //   label: this.$t("list.region"),
        //   width: ""
        // },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "parkType",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          // formatter: (row, column) => {
          //   let val = "";
          //   this.parkType.forEach((e) => {
          //     if (e.code === row.parkType) {
          //       val = e.desc;
          //     }
          //   });
          //   return val;
          // },
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        money1: "",
        money2: "",
        debtOrderId: "",
        modifyType: "", // 修改原因
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "", // 时间段
        endTime: "",
        startTimeModify: "", // 订单修改时间段
        endTimeModify: "",
        name: "停车欠费订单",
      },
      exportData: {},
      areaIds: "",
    };
  },
  methods: {
    goquery() {
      this.$router.push({
        path: "/arrearageRecord",
      });
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    timeChangeModify(timeArr) {
      this.formInline.startTimeModify = timeArr[0];
      this.formInline.endTimeModify = timeArr[1];
    },

    dialogShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.getDebtMoney();
      });
    },
    getDebtMoney() {
      this.$axios.get("/acb/2.0/bigDebtRule/getLatestRecord").then((res) => {
        this.arrearsForm.debtMoney = res.value.debtMoney
          ? (res.value.debtMoney / 100).toFixed(2)
          : "";
      });
    },
    submit() {
      this.$refs.form.validate((v) => {
        if (v) {
          this.dialogVisible = false;
          let debtMoney = this.arrearsForm.debtMoney * 100;
          this.$axios
            .post("/acb/2.0/bigDebtRule/create", {
              data: {
                debtMoney,
              },
            })
            .then(() => {
              this.$message.success("操作成功！");
            });
        }
      });
    },
    // setStartTime(value) {
    //   this.formInline.startTime = dateFormat(value);
    // },
    // setEndTime(value) {
    //   this.formInline.endTime = dateFormat(value);
    // },
    resetForm() {
      this.formInline = {
        money1: "",
        money2: "",
        debtOrderId: "",
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryExitType: 1,
        startTime: "",
        endTime: "",
        startTimeModify: "",
        endTimeModify: "",
        modifyType: "",
      };
      this.$refs.timeRangePicker.resetTime();
      // this.$refs.timeRangePickerOut.resetTime();
      this.$refs.timeRangePickerModify.resetTime();
      this.$refs.parkInput.setValue();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
        //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
        // }
      });
    },
    showLog(name, start, end) {
      if (start && end) {
        let time = new Date(end) - new Date(start);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert(name + "查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    getChildrenArea() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: this.formInline.areaId,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.carId = "";
        return;
      }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            slaveRelations: "0,1",
            operationId: this.formInline.operationId,
            areaIds: areaId,
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searchData() {
      if (this.formInline.startTime || this.formInline.endTimeModify) {
        let areaId = "";
        if (this.$refs.cascader) {
          areaId = this.$refs.cascader.getStreetId()
            ? this.$refs.cascader.getStreetId()
            : this.$refs.cascader.getAreaId();
        }
        console.log(this.formInline);
        let opt = {
          method: "get",
          url: "/acb/2.0/debtOrder/queryDebtOperationLog",
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            parkType: this.formInline.parkType,
            startTime: this.formInline.startTime, // 时间段
            endTime: this.formInline.endTime,
            modifyStartTime: this.formInline.startTimeModify, // 订单修改时间段
            modifyEndTime: this.formInline.endTimeModify,
            entryExitType: this.formInline.entryExitType,
            areaId: areaId,
            operationId: this.formInline.operationId,
            parkId: this.formInline.parkId,
            carId: this.formInline.carId,
            plateNumber: this.formInline.plateNumber,
            parkRecordId: this.formInline.debtOrderId, // 停车订单ID
            // parkTime: this.formInline.parkTime, // 停车时长
            modifyReason: this.formInline.modifyType, // 修改原因
            minMoney: this.formInline.money1 ? this.formInline.money1 * 100 : "", // 欠费区间1
            maxMoney: this.formInline.money2 ? this.formInline.money2 * 100 : "", // 欠费区间2
          },
          success: (res) => {
            if (res.state == 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            } else {
              this.$alert(res.msg, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
            this.loading = false;
          },
        };
        this.loading = true;
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.$request(opt);
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      // if (this.formInline.plateNumber.length < 3) {
      //   this.formInline.carId = "";
      // }
      // let flag = this.showLog(
      //   "时间段：",
      //   this.formInline.startTime,
      //   this.formInline.endTime
      // );
      // let flagModify = this.showLog(
      //   "订单修改时间：",
      //   this.formInline.startTimeModify,
      //   this.formInline.endTimeModify
      // );
      // if (!flag) return;
      // if (!flagModify) return;

      // if (this.formInline.startTime || this.formInline.endTimeModify) {
      //   this.loading = true;
      //   this.$refs.parkInput.setShowVal(this.formInline.parkName);
      //   this.$request(opt);
      // }
    },
    exportFile() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      const params = {
        parkType: this.formInline.parkType,
        startTime: this.formInline.startTime, // 时间段
        endTime: this.formInline.endTime,
        modifyStartTime: this.formInline.startTimeModify, // 订单修改时间段
        modifyEndTime: this.formInline.endTimeModify,
        entryExitType: this.formInline.entryExitType,
        areaId: areaId,
        operationId: this.formInline.operationId,
        parkId: this.formInline.parkId,
        carId: this.formInline.carId,
        plateNumber: this.formInline.plateNumber,
        parkRecordId: this.formInline.debtOrderId, // 停车订单ID
        // parkTime: this.formInline.parkTime, // 停车时长
        modifyReason: this.formInline.modifyType, // 修改原因
        minMoney: this.formInline.money1 ? this.formInline.money1 * 100 : "", // 欠费区间1
        maxMoney: this.formInline.money2 ? this.formInline.money2 * 100 : "", // 欠费区间2
      };
      const qsParams = qs.stringify(params, {
        arrayFormat: "repeat",
      });
      this.$axios
        .post(
          `/acb/2.0/debtOrder/queryDebtOperationLog/export?${qsParams}`,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          // if (hint.length > 0) {
          //   MessageBox.alert(hint, this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
          // }
        });
    },
    handleExportFile() {
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/debtOrder/queryDebtOperationLog/export",
        methods: "post",
        data: data,
      };
    },
  },
  components: {
    myComponent,
    // exportFile,
    timeRangePick,
  },
  created() {
    // this.$route.meta.keepAlive = true;
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData();
    this.getParkSpinner();
    // this.getMerchant();
    // this.getPay();
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
    console.log("订单修改记录=>", this.$route.meta.authority);
  },
  mounted() {
    let data = JSON.parse(JSON.stringify(this.formInline));
    data.areaId = "";
    if (this.$refs.cascader) {
      data.areaId = this.$refs.cascader.getStreetId()
        ? this.$refs.cascader.getStreetId()
        : this.$refs.cascader.getAreaId();
    }
    // 导出数据
    this.exportData = {
      url: "/acb/2.0/debtOrder/export",
      methods: "get",
      data: data,
    };
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.dialogVisible {
  >>> input[type=number]::-webkit-inner-spin-button, >>> input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  >>> input[type=number] {
    -moz-appearance: textfield;
  }
}

.orderNumberStyle {
  color: #0f6eff;
}

.goquery {
  color: blue;
  cursor: pointer;
}
</style>
