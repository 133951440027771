<template>
  <div>
    <!-- <div class="breadcrumb"> -->
    <!-- </div> -->
    <div class="tab-wrapper">
      <el-tabs v-model="tabsVal" @tab-click="tabsHandleClick">
        <!--3.0迁移-停车欠费订单-->
        <el-tab-pane label="欠费订单查询">
          <parkingArrearageOrder />
        </el-tab-pane>
        <!--3.0新增-->
        <el-tab-pane label="订单修改记录">
          <orderModificationRecord />
        </el-tab-pane>
        <!-- 3.0迁移-退款管理-欠费订单修改-修改记录查询 -->
        <el-tab-pane label="历史欠费修改记录">
          <amendantRecord1 />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import parkingArrearageOrder from "./parkingArrearageOrder.vue"; // 订单欠费查询
import orderModificationRecord from "./orderModificationRecord.vue"; // 订单修改记录
import amendantRecord1 from "@/views/Owetomodify/amendantRecord"; // 历史欠费修改记录
export default {
  name: "oweToModify",
  components: {
    parkingArrearageOrder,
    orderModificationRecord,
    amendantRecord1,
  },
  data() {
    return {
      tabsVal: "0",
      authority: this.$route.meta.authority,
    };
  },
  watch: {},
  methods: {
    tabsHandleClick(tab) {
      if (this.tabsVal != "1") {
        // this.$route.path.replace()
      } else {
        // tab.$children[0].searchData()
      }
    },
  },
  created() {},
  mounted() {
    // if (this.$route.params.tabsVal) {
    //   this.tabsVal = this.$route.params.tabsVal
    // }
  },
};
</script>
<style media="screen">
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form
  padding-top 25px
  box-sizing border-box
.content
  height 50px
  margin-top 18px
.el-form-item
  display inline-block
.options-bottom
  display flex
.inquire
  margin-left 10px
.tab-wrapper
  padding: 0 10px;
</style>
